.profile-header-content {
    padding: 30px;
    display: flex;
    align-items: center;
}
.avatar-uploader > .ant-upload {
    width: 60px;
    height: 60px;
  }
  .hidden{

    display: none;
}
.avatar_input_file{
	opacity: 0;
    z-index: 999;
}
.avatar_edit_img.avatar_input_file{
    overflow: hidden;
    padding:15px;
    text-indent: 15px;
    border-radius: 50px;
}
.prof_avtar{
    height: 84px;
    width: 84px;
    box-shadow: 0px 3px 6px #00000012;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
}
.prof_avtar .avatar_img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.profile-header-content-two{
    display: block !important;
}
.profile_value{
    margin-bottom: 10px;
    display: flex;
}
.profile_value img{
    margin-right: 8px;
}
.profile_value .profile_value_span{
    color: #2D2D2D;
    font: normal 16px/21px "Roboto" !important;
    
}
.profile_value .edit_icon{
    margin-left: 21px;
    margin-bottom: 4px;
}
.profile_value_seperator{
    margin-left: 16px;
    margin-right: 16px;
}
.profile_value .edit-input img{
    align-self: center;
    margin-left: 10px;
}
.division_img{
    align-self: start;
}
.profile_value_two{
    color: #8D8D8D !important;
}
.key_name {
    color: #858585;
    font: normal 16px/21px "Roboto" !important;
    margin-bottom: 10px;
}
.card-header h1, .card-header h2, .card-header h3, .card-header p {
    color: rgb(248, 244, 244);
    margin: 0px;
}
.prof_name h2{
    color: #FFFFFF;
    font: normal 28px/36px "Roboto";
}
.prof_name p{
    color: #FCFCFC !important;
    font: normal 18px/24px "Roboto" !important;
}
.avatar_edit_img{
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30px;
    width: 30px;
} 
.division_value{
    display: flex;
    margin-bottom: 9px;

}
.division_value .profile_value_span_key{
    min-width: 60px;
    display: block;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.profile_ErrorMassage{
    margin-bottom: 5px;
    display: block;
    margin-left: 25px;
}
.pro_box_ht{
    height: 150px;
}
.pro_loding{
    position: relative;
    top: -64px;
    left: 15%;
}
.showPhone,.showAddress{
    display: none;
}
.manage-accoutn-container{
    min-height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.manage-accoutn-container .signup-body{
    margin:30px;
    padding:0;
}
.prof_avtar{
    position: relative;
}
.avatar_input_file_container::after{
    content:"Please Upload a file with these extensions: jpg, gif, png, jpeg.";
    position: absolute;
    font: normal 12px/16px "Roboto";
    color : #c0cce2;
    left:0;
    bottom: -25px;
    opacity: 0;
    z-index: 0;
    transition: 0.2s linear all;
    width: 350px;
}
.avatar_input_file_note::after{
    opacity: 1;
    z-index: 1;
}
@media (max-width:1130px){
    .manage-accoutn-container{
        min-height: calc(100vh - 67px);
    }
}
@media (max-width:767px){
    .manage-accoutn-container .signup-body{
        margin:30px 15px;
    }
}
@media (max-width:480px){
    .manage-accoutn-container .signup-body{
        margin:30px 5px;
    }
}