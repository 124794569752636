.emptypage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.company-comp .Component-horizontalScrollContainer-22{
  width: 99%;
  margin: 0 auto;
}

.add_company_dialog .MuiDialog-paperWidthMd{
  max-width: 680px;
}
.closeBtn{
  position: absolute !important;
  right: 0;
}
.add_company_dialog .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border: 1px solid #14193A !important;
  
}
.add_company_dialog .MuiSelect-outlined.MuiSelect-outlined{
    padding-left: 0px;
    background: transparent;
}
#form-dialog-title h2{
  display: flex;
  width: 100%;
  align-items: center;
}
#form-dialog-title h2 img{ 
  margin-right: 10px;
}
#form-dialog-title h2 span{
  font: bold 18px/24px "Roboto" !important;
  align-self: center;
  color: #424242;
}
.add_company_dialog .MuiDialogTitle-root{
  padding:20px 20px 26px 20px
}
.add_company_dialog .MuiDialogContent-root{
  padding: 10px 20px 20px 20px;
  overflow-x: hidden;
}
.add_company_dialog label{
  font: 500 16px/21px "Roboto"  !important;
  margin-bottom: 8px;
  color: #333758 !important;
  display: inline-block;
}
.add_company_dialog input, .customSelect{
  padding: 13px 17px;
  font: normal 16px/21px "Roboto"  !important;
  color: #424242;
}
.add_company_dialog  .MuiFormControl-marginDense, .add_company_dialog .MuiFormControl-root{

}
.add_company_action{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.add_company_action .cancel_btn{
  width: 130px;
  color: #424242;
  height: 40px;
  border: 1px solid #424242;
  margin-right: 15px;
  font: 500 16px/21px "Roboto" !important;
  text-transform: capitalize;
}
.add_company_action .ok_btn{
  background: transparent linear-gradient(180deg, #6D99FC 0%, #376CE2 100%) 0% 0% no-repeat padding-box;
  box-shadow: none;
  color: #FFFFFF;
  font: 500 16px/21px "Roboto" !important;
  text-transform: capitalize;
  height: 40px;
  min-width: 130px;
}
.custom_upload{
  position: relative;
  height: 38px;
  margin-bottom: 10px;
}
.custom_upload .input_one {
  left: 0;
  height: 38px;
  position: absolute;
  width: 71%;
  opacity: 0;
  z-index: 999;
}
.custom_upload .input_two{
  position: absolute;
  padding: 8px 11px;
  left: 0;
  max-height: 38px;
  border: 1px solid #E2E5F2;
  border-radius: 5px;
  width: 71%;
  margin-bottom: 10px;
  font: normal 16px/21px "Roboto" !important;
  color: #B5B5B5;
  background-color: #FAF9FE;
}
.add_company_dialog .MuiFormControl-root, .add_company_dialog .MuiInputBase-root{
  height:38px !important;
  
}
.custom_upload button{
  position: absolute;
  right: 0;
  border: 1px solid #21BF73;
  background-color: #21BF730F;
  box-shadow: none;
  text-transform: capitalize;
  font: normal 16px/21px "Roboto" !important;
  color: #21BF73;
  width: 145px;
  height: 39px;
}
.MuiTablePagination-toolbar p:first-of-type{
  display: block !important;
}
.Comp-Table .Component-horizontalScrollContainer-17,.Comp-Table .jss17{
  margin: 0px 5px;
}
.Comp-Table .MuiTableHead-root {
  border-radius: 5px;
}
.Comp-Table table {
  border-collapse: separate;
}
.address-field{
  max-width: 0;
  overflow: hidden;    
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal-err-msg{
  color: red;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
}
.Comp-Table .MuiFormControl-root, .Comp-Table .MuiOutlinedInput-root{
  height: 40px !important;
}
.filter-search{
  display: flex;
  justify-content: space-between;

  padding: 17px 20px 17px 20px;
}
.add_task_dialog .MuiFormControl-root.MuiTextField-root.toDate {
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px){
  .add_company_dialog .MuiDialog-paper{
      margin: 0;
      max-width: 100%;
      max-height: none;
 
  }
  .custom_upload{
      height: auto;
  }
  .custom_upload .input_one{
      height: 100%;
  }
  .custom_upload .input_two{
      width: auto;
      position: unset;
  }
  .custom_upload button{
      position: unset;
      width: 100%;
  }
  .filter-search{
    flex-wrap: wrap;
  }
  .filter-search > div:first-child{
    width:100%;
  } 
  .new_custom_filters .new_custom_filter_div {
    margin-bottom: 10px;
  }
  .add_company_dialog.suspend_modal .MuiDialog-paper{
    height:auto;
    margin:0 5px; 
  }
}

.emptypage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error_main, .error-page {
  height: 100%;
margin: auto;
}
.rotate {
animation: rotation 2s;
}

.linear {
animation-timing-function: linear;
}

.infinite {
animation-iteration-count: infinite;
}
.error-page, .error-page_wrap {
display: flex;
align-items: center;
justify-content: center;
}
.error-page {
flex-direction: column;
}
.four {
color: #303960;
font-size: 210px;
font-family: 'Roboto';
margin: auto;
}
.error_head {
font: bold 24px "Roboto";
color: #424242;
}
.error-txt_p {
font: normal 16px "Roboto";
color: #808080;
margin-bottom: 30px;
}
.error-text {
text-align: center;
}
.error_back {
font: normal 16px "Roboto";
background-color: #303960;
color: #fff;
border: none;
padding: 12px 44px;
cursor: pointer;
}
.update_status_dialog .MuiDialog-paperWidthSm {
  width: 680px;
}
@keyframes rotation {
from {
transform: rotate(0deg);
}
to {
transform: rotate(359deg);
}
}
