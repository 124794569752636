
.signup-body-bg-color{
    background-color: #f2f4fb;
    min-height : 653px;
}
.clinic-signup-root{
    max-width: 1008px;
    margin: auto;
  }

  .clinic-signup-root .card-header{
    background: transparent linear-gradient(270deg, #789DEF 0%, #233687 100%) 0% 0% no-repeat padding-box;
  }
  .clinic-signup-root .header-content{
    padding: 30px 20px 20px 20px;
}
.signup-logo{
    display: flex;

}
.register-signup-logo{
    align-items: center;
}
.signup-logo img {
    padding-right: 20.5px;
    border-right: 1px solid #fff;
    margin-right: 20.5px;
}

.signup-logo-text h3{
    font: 500 18px/24px "Roboto" !important;
    color: #FFFFFF;
    margin: 0px;
}


/* .loginLabel{
    font: normal 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #333758 !important;
    margin-top: 10px;
} */
.checkbox-label{
    margin-bottom: 0px !important;
    margin-top: -15px;
    display: flex !important;
    align-items: start !important;
}

.checkbox-label .MuiTypography-root{
    font: normal 16px/21px "Roboto" !important;
    color: #707070;
    padding-top: 9px;
}
.RegisterBtn{
   
    margin-top: -6px !important;
}
.custom-upload-doc {
    position: relative;
}
.custom-upload-doc .MuiFormControl-root{
    opacity: 0;
    position: absolute;
}
.custom-upload-doc-container span{
    color: #376CE2;
    cursor: pointer;
    font-weight: 500;
}
.custom-upload-doc-container {
    display: flex;
    justify-content: space-between;
    padding: 11px 17px;
    background-color: #FBFBFB;
    border-radius: 5px;
    border: 1px dashed #C7C7C7;
}
.MuiPaper-elevation1{
    box-shadow: 0 10px 10px -10px rgba(39, 4, 15, 0.35) !important;
}

.signup-body label{
    font: 500 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #303960 !important;
    display: block;
}
.signup-body input, .customSelect{
    padding: 13px 17px;
    font: normal 16px/21px "Roboto"  !important;
    color: #424242;
}
.signup-body .MuiAutocomplete-inputRoot{
    padding: 13px 17px !important;
}
.signup-body .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
    padding: 0px !important;
}
.signup-body .MuiFormControl-root, .signup-body .MuiInputBase-root{
    height:45px !important;
}
.signup-body .register-form {
    padding: 20px;
}
.register-form .register-form-header{
    margin: 0px;
    font: 500 21px/28px "Roboto" !important;
    color: #424242;
    margin-bottom: 27px;
}
.signup-body-bg-color.signup-section{
    min-height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup-body-bg-color.signup-section .signup-body{
    margin-top: 30px;
    margin-bottom: 30px;
}
.register-form-top .register-form-left, .register-form-top .register-form-right{
    padding-bottom:0 !important; 
}
.address_field.form_input_field input{
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #F2F2F2;
    height:45px !important;
    border:0;
    border:1px solid transparent !important;
}
.address_field.form_input_field input::placeholder{
    color:#A1A1A1;  
}
.address_field.form_input_field input:focus{
    border:1px solid #14193A !important;
    outline:none;
}
@media (max-width: 767px) {
    .signup-body-bg-color.signup-section .signup-body {
        padding: 0px;
        margin: 64px 15px;
    }
    
    .signup-logo{
        display: block !important;
    }
    .signup-logo img{
        border: none;
        margin-bottom: 20px;
    }
}
.modal-err-msg{
    color: red;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 400;
    display: block;
    margin-bottom: 15px;
}
.reg_error{
    color: red; 
    font-size: 14px;
}
.under_line{
    text-decoration: underline;
}

.form_input_field label{
    margin-bottom:3px !important;
}
.form_input_field{
    position: relative;
}
.form_input_field span.error{
    position: absolute;
    bottom:3px;
}
.hospital_field span.error, .document_field span.error{
    bottom : -17px;
}
.document_field .custom-upload-doc{
    margin-bottom: 20px;
}
.document_field .custom-upload-doc > div{
    margin-bottom: 0;
}
.registration-section .checkbox-label{
    margin-bottom:5px !important
}

@media (max-width: 599px){
    .register-form-left{
        padding-bottom:0 !important;
    }
    .register-form-right{
        padding-top: 0 !important;
    }
}
@media (max-width: 480px) {
    .signup-body-bg-color.signup-section .signup-body {
        margin: 64px 5px;
}
}