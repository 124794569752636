.taskfilter{
    display: flex;
    /* margin-bottom: 25px; */
    padding: 0 10px;
    flex-wrap: wrap;

}
.filterSearch{
    float: right;
    display: flex;
    justify-content: space-between;
    /* background: white; */
    padding: 17px 20px 17px 20px;
}
.add_task_dialog .react-tag-input {
    border: none;
    min-height: 38px;
    margin-bottom: 15px;
    background: #f2f2f2;
    border-radius: 5px;
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
}
.MuiGrid-container.MuiGrid-root.MuiGrid-spacing-xs-2.mt-2 {
    margin-top: 7px;
}
.task_custom_filter_label{
    font: normal 16px/21px "Roboto" !important;
    color: #B2B2B2 !important;
    margin-right: 13px !important;
    align-self: center !important;
    margin-bottom: 25px;
}
.taskfilter .MuiFormControl-root, .taskfilter .MuiAutocomplete-root{
    height: 40px !important;
    border-radius: 5px;
    margin-right: 15px;
    margin-bottom: 25px;
    background-color: #fff !important;
}
.taskfilter .ant-picker-range{
    width: 240px;
    border-radius: 5px;
    margin-bottom: 25px;
    margin-right: 15px;
    font: normal 16px/21px "Roboto" !important;
    color: #505050 !important;
    padding: 11px 13px 9px 13px;
    height: 40px;
}
.taskfilter .ant-picker:hover, .ant-picker-focused{
    box-shadow: none !important;
    border-color: #14193A !important;
}
.taskfilter .ant-picker-range.ant-picker-focused .ant-picker-active-bar{
    opacity: 0 !important;
}
.task-detail-header{
    margin-bottom: 22px;
}
.task-detail-stepper{
    display: flex;
    background: #fff;
    box-shadow: 0px 5px 35px #0000000A;
    border-radius: 5px;
    padding: 24px 0px 24px 20px;
    margin-bottom: 30px;
}
.task-detail-stepper-item {
    position: relative;
    width: 25%;
    text-align: right;
}
.task-detail-stepper-pending:before{
    background-color: #617FFF;
}
.task-detail-stepper-assigned:before{
    background-color: #303960;
}
.task-detail-stepper-inprogress:before{
    background-color: #69F3B1;
}
.task-detail-stepper-completed:before{
    background-color: #21BF73;
}
.task-detail-stepper-uncovered:before{
    background-color: #D24856;
}
.task-detail-stepper-gray:before{
    background-color: #888888;
}
.task-detail-stepper-diabled:before{
    background-color: #DBDBDB;
}
.task-detail-stepper-item:before {
    content: "";
    position: absolute;
    /* background-color: red; */
    left: 0;
    height: 4px;
    top: calc(50% - 2px);
    width: calc(100% - 66px);
    border-radius: 21px;
}
.task-detail-stepper-item img {
    margin-right: 10px;
}
.task-detail-stepper-item span{
    position: absolute;
    left: 0;
    bottom: 0;
    font: 500 16px/21px "Roboto" !important;
    color: #424242;
    overflow: hidden;
    text-overflow: ellipsis;
}
.task-detail-timeline .task-detail-timeline-header {
    color: #424242;
    font: 600 18px/24px "Roboto" !important;
    border-bottom: 1px solid #CECECE;
    padding-bottom: 12px;
    margin: 0px;
}
.task-detail-timeline-card {
    box-shadow: 0px 3px 6px #E1E1E129;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px 20px;
    /* border-left: 8px solid #21BF73; */
    margin-bottom: 20px;
    position: relative;
}
.dark_blue_color{
    border-left: 8px solid #303960;
}
.light_green_color{
    border-left: 8px solid #67f3b1;
}
.dark_green_color{
    border-left: 8px solid #21BF73;
}
.gray_color{
    border-left: 8px solid #888888;
}
.light_blue_color{
    border-left: 8px solid #617FFF;
}
.red_color{
    border-left: 8px solid #D24856;
}


.dark_blue_color:before{
    border: 2px solid #303960;
}
.light_green_color:before{
    border: 2px solid #67f3b1;
}
.dark_green_color:before{
    border: 2px solid #21BF73;
}
.gray_color:before{
    border: 2px solid #888888;
}
.light_blue_color:before{
    border: 2px solid #617FFF;
}
.red_color:before{
    border: 2px solid #D24856;
}
.task-detail-timeline-one {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.task-detail-timeline-one-name{
    color: #424242;
    font: 500 16px/21px "Roboto" !important;
   
}
.task-detail-timeline-dtime{
    align-self: center;
    color: #AAAAAA;
    font: 500 14px/19px "Roboto" !important
}
.task-detail-timeline-two .task-detail-timeline-two-status{
    color: #21BF73;
    font: 500 18px/24px "Roboto" !important;
    margin-bottom: 5px;
}
.task-detail-timeline-three-text{
    color: #424242;
    font: 500 18px/24px "Roboto" !important;
}
.task-detail-timeline-main{
    padding: 20px 0px 0px 36px;
    margin-left: 36px;
    border-left: 1px solid #303960;
}
.task-detail-timeline-card:before {
    content: "";
    background-color: #DFE5FF;
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    /* border: 2px solid #21BF73; */
    top: calc(50% - 12.5px);
    left: -56.5px;
}
.tab-card-container .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
    border: none !important;
}
.tab-card-container .ant-tabs-tab{
    margin: 0 !important;
    border: none !important;
    border-radius: 5px 5px 0px 0px !important;
    height: 38px;
    width: 144px;
    text-align: center;
    background-color: transparent !important;
    font: 500 16px/21px "Roboto" !important;
    
    box-shadow: none;
}
.tab-card-container .ant-tabs-tab .ant-tabs-tab-btn{
    color: #646670 !important;
    width: 100%;
    font-weight: 600;
}
.tab-card-container .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
    
    background: #fff !important;
}
.tab-card-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #303960 !important;
    font-weight: 600 !important;
}
.tab-card-container .ant-tabs > .ant-tabs-nav{
    margin: 0px !important;
}
.tab-card-container .ant-tabs {
    box-shadow: 0px 2px 1px #0000000F;
    border-radius: 0px 5px 5px 5px;
    margin-bottom: 30px;
}
.tab-card-container .ant-tabs-content-holder {
    padding: 20px 0px;
    background-color: #fff;
    border-radius: 0px 5px 5px 5px;
}
.tab-card-container .ant-tabs-content-holder .tab-container-title{
    color: #424242;
    font: 600 18px/24px "Roboto" !important;
    padding: 0px 10px 10px 12px;
    border-bottom: 1px solid #F2F4FB;
    margin-bottom: 12px;
}

.tab-container-expense {
    display: flex;
    padding: 0px 30px 11.5px 42px;
    justify-content: space-between;
    border-bottom: 1px solid #E2E2E2;
    margin-bottom: 15.5px;
}

.tab-container-expense-type {
    margin: 0;
    color: #2D2D2D;
    font: 600 18px/24px "Roboto" !important;
    position: relative;
}

.tab-container-expense-doller {
    color: #2D2D2D;
    font: 600 18px/24px "Roboto" !important;
    margin-right: 27px;
}
.tab-container-expense-remove {
    color: #6A6A6A;
    font: 600 16px/21px "Roboto" !important;
    cursor: pointer;
}
.tab-container-expense-user {
    display: flex;
    justify-content: space-between;
    padding: 0px 116px 14px 42px;
}
.tab-container-expense-user-name img {
    margin-right: 9px;
}
.tab-container-expense-user-name span {
    color: #707070;
    font: normal 18px/24px "Roboto" !important;
}
.tab-container-expense-user-name, .tab-container-expense-dtime{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.tab-container-expense-date {
    color: #707070;
    font: normal 18px/24px "Roboto" !important;
    margin-right: 20px;
}
.tab-container-expense-time {
    color: #2D2D2D;
    font: 600 18px/24px "Roboto" !important;
}
.tab-container-expense-add-btn{
    margin: 0px 30px 18px 42px;
}
.tab-container-expense-add-btn button{
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background-color: #F9F9F9;
    padding: 15px 0px 10px 0px;
}
.tab-container-expense-add-btn button span{
    color: #949494;
    font: normal 14px/19px "Roboto" !important;
}
.total_expense{
    color: #303960;
    font: normal 16px/21px "Roboto" !important;
    padding-right: 30px;
    text-align: right;
}
.tab-container-expense-type::before {
    content: "";
    position: absolute;
    left: -32px;
    background-color: #C9FBE3;
    height: 16px;
    width: 16px;
    border: 1px solid #21BF73;
    border-radius: 50%;
}
.tab-card-container-timeline {
    display: flex;
    padding: 0px 12px;
}
.tab-card-container-timeline  button{
    height: 38px;
    margin-left: 20px;
    background-color: #21BF730F;
    margin-top: 2px;
    border: 1px solid #21BF73;
    width: 110px;
}
.tab-card-container-timeline  button span{
    font: normal 14px/19px "Roboto" !important;
    color: #21BF73;
    text-transform: capitalize;
}
.tab_product_container{
    padding: 0px 10px;
}
.task-detail .add_dept_btn {
    margin: 0px !important;
    border: 1px dashed #D4D4D4 !important;
    background-color: #FCFCFC !important;
    height: 50px;
}
.task-detail .add_dept_btn .MuiButton-label{
    color: #303960 !important;
    font: normal 16px/21px "Roboto" !important;
}
.info-card-detail-contact {
    border: 1px solid #F3F3F3;
    margin-bottom: 10px;
}
.info-card-detail-left.info-card-detail-left-contact {
    max-width: none;
    margin-bottom: 15px;
}
.info-card-detail-left.info-card-detail-left-contact img{
    align-self: start;
    margin-top: 5px;
}
span.custom-span.info-card-detail-left-contact-name {
    font: normal 14px/19px "Roboto" !important;
    width: 100%;
    padding: 6px 15px;
    background-color: #F3F3F3;
}
.info-card-detail-left-contact-data{
    padding: 0px 15px 15px 15px;
}
.task-detail-header-btns {
    display: flex;
}
.task-detail-header-btns .assigned-to-btn{
    font: normal 16px/21px "Roboto" !important;
    color: #878787;
    height: 40px;
    padding: 10px 13px;
    box-shadow: 0px 2px 2px #0000001C;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    max-width: 230px;
    min-width: 230px;
    justify-content: space-between;
}
.assigned-to-btn-edit .edit-input .MuiAutocomplete-root .MuiFormControl-root .MuiAutocomplete-inputRoot {
    padding-right: 25px !important;
}
.task-detail-header-btns .assigned-to-btn span{
    white-space: nowrap;
}
.task_detail_drop_btn{
    display: flex;
}
.assigned-to-btn-edit{
    padding: 0px 10px 0px 0px !important;
}
.task-detail-header-btns .custom-span{
    overflow: hidden;
    text-overflow: ellipsis;
}
.task-detail-header-btns .assigned-to-btn img{
    margin-left: 10px;
    margin-bottom: 4px;
}
.task-detail-header-btns  .update_status_btn{
    height: 40px;
    margin-left: 10px;
    background-color: #21BF730F;
    border: 1px solid #21BF73;
    padding: 9px 30px 11px 30px;
    /* margin-right: 10px; */
}
.task-detail-header-btns  .update_status_btn span{
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
    white-space: nowrap;
    text-transform: capitalize;
}
.task-detail-header-btns .task_detail_cancel_btn {
    height: 40px;
    margin-left: 10px;
    background-color: #FFFFFF;
    border: 1px solid #BEBEBE;
    padding: 10px 40px;
    
}
.task-detail-header-btns .task_detail_cancel_btn span{
    font: normal 16px/21px "Roboto" !important;
    color: #6A6A6A;
    
    text-transform: capitalize;
}
.task_detail_dialog .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.task-detail-header-btns .assigned-to-btn .edit-input{
    width: 100%;
}
.cal-info-card-detail-left .edit-input{
    width: auto !important;
}
.cal-info-card-detail-left{
    max-width: none !important;
}
.task_conflict_border .MuiDialog-paperWidthSm{
    border: 2px solid #FF6F5E;
}
.task_emergency .MuiDialog-paperWidthSm{
    border: 2px solid #F6AC4C;
}

.task_detail_container_item {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
}
.task_detail_container_item h1 {
    font: 500 16px/19px "Roboto";
    color: #424242;
    padding: 16px 20px 13.5px 20px;
    margin-bottom: 17.5px;
    border-bottom: 1px solid #F2F2F2;
}
.task_detail_item_container {
    padding: 0px 20px 1px 20px;
}
.task_detail_item_container p {
    color: #303960;
    font: 500 14px/17px "Roboto";
    margin-bottom: 12px;
}
.task_detail_item_value {
    color: #2D2D2D;
    font: normal 14px/17px "Roboto";
    margin-bottom: 17px;
}
.task_detail_container{
    margin-bottom: 20px;
}
.task_detail_item_value img{
    margin-right: 8px;
}
.task_detail_container_item span{
    margin-left: 4px;
    color: #376CE2;
}
.task_detail_item_value_cal{
    display: flex;
    justify-content: space-between;
}
.task_detail_table_header{
    font: 500 16px/19px "Roboto";
    color: #424242;
    padding: 11px 40px 8px 40px;
    margin: 0px;
    display: inline-flex;
    background-color: #fff;
    border-radius: 5px 5px 0px 0px;
}
.task_detail_container_itemtask_detail_table_container {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px 10px;
    box-shadow: 0px 2px 1px #0000000F;
    border-radius: 0px 5px 5px 5px;
}
/* duration */
.estimate-time-wrapper{
    display:flex;
}
.task_detail_item_container.task_detail_item_value_cal{
    padding-bottom: 17px;
}
.task_detail_item_container.task_detail_item_value_cal .task_detail_item_value { 
    margin-bottom: 0;
}
.task_detail_item_container.task_detail_item_value_cal .text_area, .text_area .MuiInputBase-multiline{
    height: 100% !important;
}

.add_company_dialog .MuiFormControl-root.multiline-text, .add_company_dialog .multiline-text .MuiInputBase-root{
    height: auto !important;
}
.add_company_dialog .multiline-text .MuiInputBase-root {
    padding: 10px 14px;
}
.contact_detail{
    display: flex;
}
.contact_detail span{
    color: #2D2D2D;
    margin-left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add_task_modal .form_input_field span.error{
    bottom: -17px;
}
.task_date_rage .ant-picker-suffix{
    display: inline-flex;
}
.add_account_dialog .grey-input-field .MuiInputBase-root{
    background-color: unset;
}
.ant-picker-panel-container{
    margin: 15px 0;
}
.custom-ant-select .label {
    display: block;
    margin: 0px;
}
.custom-ant-select .ant-select-selector{
    align-items: center;
}
.custom-ant-select .ant-select-selection-item-content, .custom-ant-select .ant-select-selection-item-content > span{
    display: inline-flex !important;
    align-items: center;
}
.custom-ant-select .ant-select-selection-item img{
    width: 20px !important;
    height: 20px !important;
}
.custom-ant-select .ant-select-selection-item .auto-assignee-priority-icon{
    width: 12px !important;
}
.custom-ant-select .ant-select{
    width: 100% !important;
    margin: 2px 0px 4px 0px;
    /* height: 38px; */
}
.custom-ant-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 5px;
    border: 1px solid #E2E5F2;
    height: 38px !important;
}
.custom-ant-select .ant-select-show-search.ant-select-multiple .ant-select-selector{
    border-radius: 5px; 
    border: 1px solid #E2E5F2;
    min-height: 38px;
}
.custom-ant-select .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: transparent !important;
}
.custom-ant-select .ant-select{
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
}
.custom-ant-select .ant-picker {
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
    width: 100% !important;
    padding: 0 17px;
    border: 1px solid transparent;
    height: 38px !important;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: #F2F2F2;
}
.custom-ant-select .ant-picker:hover{
    border-color: transparent;
    border-right-width: 1px !important;    
}
.custom-ant-select .ant-picker-focused {
    border-color: #14193A;
    border-right-width: 1px !important;
}
.add_task_modal .custom-ant-select .form_input_field span.error{
    bottom: -2px;
}
.priority_icon{
    margin-left:5px;
}
.priority_container{
    padding: 16px 13px;
    background: #FFFFFF;
    box-shadow: 0px 2px 1px #0000000F;
    border-radius: 5px;
}
.add_company_dialog .priority_container label{
    margin: 0;
    font: 500 14px/19px "Roboto";
    color: #333758;
    display: inline-flex;
}
.add_company_dialog .priority_container label span.MuiFormControlLabel-label{
    margin-right:9px;
}
.priority_container .labelEmergency span{
    color: #FB4D4D;
    padding-left:6px;
}
.account-detail-header.task-detail-header > div > button {
    margin-left: 10px;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper{
    display: flex;
    align-items: flex-start;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper > span{
    display: block;
}
td.MuiTableCell-root.MuiTableCell-body.priority_icon_wrapper .priority_icon{
    margin-left:0;
    margin-right:5px;
}
.form_multi_mail_field .react-multi-email{
    border-radius: 5px;
    background-color: #F2F2F2;
    padding: 6px 13px;
    min-height:38px;
    border-color: #E2E5F2;
    border-width: 0;
    margin-bottom: 15px;
    border:1px solid transparent !important;
}
.form_multi_mail_field .react-multi-email.focused{
    border:1px solid #14193A !important;
}
.form_multi_mail_field .react-multi-email > div{
    text-align: left;
    font: normal 14px/17px 'Roboto';
    letter-spacing: 0px;
    color: #424242;
    line-height: 0;
    padding: 2px 6px;
    background-color: #DBDBDB;
    margin: 2px;
    margin-right: 10px;
    margin-left: 0;
}

.form_multi_mail_field .react-multi-email input{
    background-color: transparent;
    padding: 2px 0 !important;
    font-size: 1rem;
    color: #424242;
}
.form_multi_mail_field .react-multi-email span{
    padding:8px 0 !important;
    font-size: 1rem;
    color: rgb(0, 0, 0) !important;
    top: auto;
    left: auto;
}
.form_multi_mail_field .react-multi-email > span{
    color: rgba(0, 0, 0, 0.3) !important;
}
@media only screen and (max-width:767px){
    .tab-container-expense-user{
        padding: 0px 30px 14px 30px;
    }
    .task_detail_drop_btn{
        justify-content: space-between;
    }
    .taskfilter{
        display: block;
    }
    .task_custom_filter_label{
        margin-bottom: 5px;
    }
    .taskfilter .MuiFormControl-root, .taskfilter .MuiAutocomplete-root{
        margin-bottom: 15px;
    }
    .task-detail-stepper-item span{
        text-align: center;
        width: 100%;
        bottom: -21px;
    }
    .task-detail-stepper{
        padding: 24px 0px 24px 10px;
    }
    .tab-card-container .ant-tabs-tab{
        width: 100px;
    }

    .task-detail-stepper-item span{
        white-space: nowrap;
    }
    .account-detail-header.task-detail-header > div > button {
        margin-left: 0;
    }
}
@media only screen and (max-width: 600px){
        /* ant date picker */
        .ant-picker-dropdown .ant-picker-panels, .ant-picker-dropdown .ant-picker-datetime-panel{
            max-width: 280px;
            display: flex;
            flex-wrap: wrap !important;
        }
}
@media only screen and (max-width:479px){
    .task-detail-header-btns {
        display: initial;
    }
    .task-detail-header-btns .assigned-to-btn{
        width: 100%;
        max-width: none;
        margin-bottom: 10px;
    }
    .task-detail-header-btns .update_status_btn{
        margin: 0px;
        margin-bottom: 10px;
    }
    .task-detail-header-btns .task_detail_cancel_btn{
        margin: 0px;
    }
    .task-detail-header-btns .update_status_btn, .task-detail-header-btns .task_detail_cancel_btn{
        width: 100%;
    }
    .task_detail_drop_btn{
        display: block;
    }
}

.task-detail-header .tableTitle img{
    width:26px;
    height:22px;
    cursor: pointer;
}
.task-detail-header .tableTitle img.priority_icon{
     margin-bottom: 4px;
}
.task_detail_patient_item{
    font: 500 18px/24px "Roboto" !important;
    color: #424242;
}

.search-container {
    display: flex;
    background: #F7F7F7;
    padding: 0.5rem 0.7rem;
    border-radius: 0.4rem;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
}

.search-btn {
    background: #5A63A2;
    padding: 0.5rem;
    border-radius: 0.2rem;
    cursor: pointer;
}
  
.search-btn:hover {
    background: #373d65;
}
  
.Acc-Table .Mui-checked .MuiSvgIcon-root {
    color: #21BF73;
}

@media only screen and (max-width: 768px) {
    .search-container {
      width: 90%;
    }
    .search-back-width {
      width: 50rem;
    }
}
  
@media only screen and (max-width: 426px) {
    .search-container {
      width: 100%;
    }
    .search-back-width {
      width: 100%;
    }
}

.mr-0 {
    margin-right: 0 !important;
}
