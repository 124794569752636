/* .account-comp .MuiPaper-root{
    width: 99% !important;
    margin: 0 auto;
}
.account-comp .Component-horizontalScrollContainer-22{
    width: 99%;
    margin: 0 auto;
} */
.body-bg-color{
    background-color: #f2f4fb;
    padding-bottom: 30px;
    min-height: 100vh;
}
.header-add-btn{
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
}
.table_action_btns img{
    margin-right: 10px;
}
.table_action_btns > div {
    display: flex;
}
.Acc-Table{
    margin: 0 10px;
    border-radius: 10px;
    background-color: #ffffff;
}
.Acc-Table .MuiTableCell-head{
    background-color: rgb(234, 236, 251);
    color: rgb(90, 99, 162);
    font: bold 14px/19px "Roboto";
    border: 0px;
    padding: 19px 16px 13px 16px;
}
/* .Acc-Table .MuiToolbar-gutters{
    padding: 20px 18px;
} */
.Acc-Table .MuiTableHead-root{
    border-radius: 5px;
}
.Acc-Table table { border-collapse: separate; }
/* .Acc-Table td { border: solid 1px #000; } */
.Acc-Table th:first-child, .Acc-Table td:first-child  { border-top-left-radius: 5px; }
.Acc-Table th:first-child, .Acc-Table td:first-child  { border-bottom-left-radius: 5px; }
.Acc-Table th:last-child, .Acc-Table td:last-child  { border-top-right-radius: 5px; }
.Acc-Table th:last-child, .Acc-Table td:last-child { border-bottom-right-radius: 5px; }

.Acc-Table .Component-horizontalScrollContainer-16,.Acc-Table .jss16{
    margin: 0px 5px;
}
.Acc-Table td{
    border: none;
    font: normal 16px/19px "Roboto" !important;
    color: #303960;
    padding: 16px 13px 12px 16px;
}
.Acc-Table .table_container{
    margin: 0 5px;
}
.custom_filter{
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
    width: 106%;
    position: initial !important;
}
.Acc-Table .MuiFormControl-root, .Acc-Table .MuiOutlinedInput-root{
    height: 40px !important;
    border-radius: 5px;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid #14193A !important;
    
  }
.MuiTablePagination-toolbar .MuiInputBase-root{
width: auto !important;
}
.Component-paginationToolbar-8{
    width: auto !important;
}
.MuiDialog-paperWidthMd{
    width: 960px !important;
}
/* .Acc-Table .MuiTable-root{
    width: 99% !important;
    margin: 0 auto;
} */
.Desc_height .MuiInputBase-root{
    height: auto !important;
}
.add_contacts .MuiExpansionPanelSummary-content{
    display: flex;
    justify-content: space-between;
}
.department-expansion .MuiRadio-colorSecondary.Mui-checked,.MuiCheckbox-colorPrimary.Mui-checked {
    color: #21bf73 !important;
}
.add_dept_btn{
    width: 100%;
    margin-top: 10px !important;
    border: 1px dashed #E3E3E3 !important;
    height: 66px;
    background-color: #F4F4F54A !important;
}
.add_dept_btn .MuiButton-label{
    display: flex !important;
    flex-direction: column;
    color: #8D8D8D !important;
    font: normal 14px/19px "Roboto" !important;
    text-transform: capitalize !important;
}


.add_account_dialog  .custom_upload button{
    text-transform: inherit !important;
    font: normal 14px/19px "Roboto" !important;
}
.add_account_dialog .MuiExpansionPanel-root{
    box-shadow: none !important;
}
.department-expansion-text{
    font: normal 20px/26px "Roboto" !important;
    color: #424242;
}
.department-expansion .contact-expansion-text{
    font: normal 15px/20px "Roboto" !important;
    color: #354795;
    padding-left: 0px !important;
    text-transform: uppercase;
}
.add_more_btn{
    font: normal 14px/19px "Roboto" !important;
    color: #424242 !important;
    text-transform: inherit !important;
}
.remove_btn{
    font: normal 12px/16px "Roboto" !important;
    color: #ff1818 !important;
    letter-spacing: 0px;
    text-transform: inherit !important;
}
.add_account_dialog .MuiInputBase-root{
    background: #fff;
}
.contact-expansion .MuiExpansionPanelSummary-root{
    padding: 0px;
}
.contact-expansion .MuiExpansionPanelDetails-root {
    padding: 8px 0px;
}
.department-expansion .sub_select_input .MuiFormControl-root{
    margin: 0 !important;
}
.sub_select fieldset{
    border: 0 !important;
}
.sub_select_input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 0 !important;
}
.sub_select_input .MuiSelect-outlined.MuiSelect-outlined{
    padding-left: 10px !important;
    
}
.sub_select_input .sub_select {
    background-color: #E2E5F2 !important;
    border-radius: 0px 5px 5px 0px;
}

.sub_select_input .sub_select_edit {
    border-radius: 0px 5px 5px 0px;
}
.sub_select_edit fieldset{
    border: 0 !important;
}
.sub_select_edit input{
    color: #303960 !important;
}
.sub_select_input .MuiInputBase-root{
    padding-right: 0 !important;
}
.sub_select input{
    color: #303960 !important;
}
.add_account_check .MuiTypography-body1{
    font: normal 14px/19px "Roboto" !important;
    color: #424242 !important;
}
.account_custom_upload{
    width: 75%;
}

.account_custom_upload-half{
    width: 50%;
    float : left;
}
.hide {
    display: none;
}
.tableTitle{
    font: bold 24px/31px "Roboto" !important;
      color: #303960 !important;
      align-self: center !important;
  }
  .tableaddBtn {
    font: normal 16px/21px "Roboto" !important;
    color: #303960 !important;
    padding: 10px 29px 10px 33px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    background: none !important;
    border: 1px solid #303960 !important;
}
  .MuiInput-underline:before{
    border-bottom: 0px !important;
  }
  .Acc-Table .MuiInput-underline,.Comp-Table .MuiInput-underline{
    background: #F9F9F9 0% 0% no-repeat padding-box !important;
      border-radius: 5px !important;
      opacity: 1;
  }
 .custom_filter .MuiSelect-select{
    padding: 12px 18px 8px 18px !important;
 }
 .MuiInput-underline:after{
     border: none !important;
 }
  .MTableToolbar-searchField-11 .Mui-disabled,.MTableToolbar-searchField-10 .Mui-disabled{
    display:none !important;
  }
  .jss11 .Mui-disabled,.jss10 .Mui-disabled{
    display:none !important;
  }
  .MTableToolbar-title-10,.jss10,.MTableToolbar-title-9,.jss9{
      overflow: unset !important;
  }
  /* .MTableToolbar-title-10 .MuiFormControl-root,.jss10 .MuiFormControl-root,.MTableToolbar-title-9 .MuiFormControl-root,.jss9 .MuiFormControl-root{
    width: 125% !important;
} */
.MuiInputBase-root.MuiOutlinedInput-root.custom_filter.MuiInputBase-formControl {
    width: 125px;
}

/* .Comp-Table .MTableToolbar-root-6,.Comp-Table .jss6,.Acc-Table .jss5,.Acc-Table .MTableToolbar-root-5{
    padding: 17px 20px 17px 20px !important;
    min-height: 0px !important;
} */
  
  .Acc-Table .MuiSvgIcon-root{
    color:#D3D3D3;
  }
  .filter-class{
    position: absolute;
    right: 20px;
  }
  .Acc-Table .MuiInputLabel-outlined.MuiInputLabel-shrink,.Comp-Table .MuiInputLabel-outlined.MuiInputLabel-shrink{
    display: none;
  }
  .Acc-Table .MuiTablePagination-caption,.Comp-Table .MuiTablePagination-caption, .Acc-Table .MuiTablePagination-select,.Acc-Table  .MuiTypography-caption{
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
  }

  .custom_filter_label{
          font: normal 16px/21px "Roboto" !important;
    transform: translate(18px, 12px) scale(1) !important;
  }

.add_user_header .MuiIconButton-root:hover{
    background: none !important;
}
.Acc-Table .MuiInputBase-input,.Comp-Table .MuiInputBase-input{
    padding: 11px 0px 12px 0px;
    font: normal 16px/21px "Roboto";
    color: #303960;
}
.select_parent{
   padding: 18px 12px;
}
.select_parent .breadCrumbs{
    display: flex;
    margin-bottom: 18px;
}
.account-detail-header{
    display: flex;
    justify-content: space-between;
}
.bread-crumb-back{
    height: 18px;
    align-self: center;
    margin-right: 10px;
}
.bread-crumb-main{
    font: normal 16px/21px "Roboto" !important;
}
.bread-crumb-main .bread-crumb-link{
    color: #8F8F8F;
}
.bread-crumb-main .bread-crumb-name{
    color: #4759A7;
    line-height: normal;
}
.account-detail-container{
    margin: 0 10px;
}
.account-expansion-text{
    font: 600 18px/24px "Roboto" !important;
    color: #424242;
}
.account-detail-container .account-expansion{
    box-shadow: 0px 2px 1px #0000000F !important;
    border-radius: 5px !important;
    margin-bottom: 30px;
}
.account-expansion .MuiExpansionPanelSummary-content.Mui-expanded{
    margin: 12px 0px;
}
.account-detail-container .MuiExpansionPanelSummary-root{
    padding: 0 20px;
}
.account-detail-container .MuiExpansionPanelDetails-root{
    padding: 17px 20px;
}
.info-card{
    width: 100%;
}
.info-card .info-card-sub-name{
    font: 500 16px/21px "Roboto" !important;
    color: #6370AA;
    margin-bottom: 5px;
}
.info-card-detail-last{
    margin-bottom: 0px !important;
}
.info-card-detail{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
/* .info-card-detail img{
    align-self: start;
} */
.info-card-detail-left .custom-span{
    font: normal 18px/24px "Roboto" !important;
    color: #2D2D2D;

   
}
.info-card-detail-left img {
    margin-right: 9px;
}
.info-card-detail-left .account-des-span{
    font: normal 16px/21px "Roboto" !important;
    margin-left: 0px;
}
.info-card-detail-left{
    display: flex;
    max-width: 287px;
}

.select_parent .upload_btn{
    right: 0;
    border: 1px solid #21BF73;
    background-color: #21BF730F;
    box-shadow: none;
    text-transform: capitalize;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
    width: 163px;
    height: 41px;
}



.save_btn{
    right: 0;
    border: 1px solid #21BF73 !important;
    background-color: #21BF730F !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73 !important;
    width: 110px;
    height: 38px;
}
/* .addnoteHeight{
    height:38px !important;
    width: 700px !important;
} */



.account-detail-container-right-btns{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.account-detail-container-right-btns .account-btns{
    text-transform: capitalize !important;
    font: normal 14px/19px "Roboto" !important;
    border: 1px solid #C5C5C5 !important;
    border-radius: 25px !important;
    background-color: #fff !important;
    width: 130px;
    height: 38px;
    margin-right: 20px !important;
}
.info-card-detail-right{
    display: flex;
}
.info-card .info-card-detail-right .info-card-sub-name{
    margin: 0 !important;
    margin-right: 10px !important;
}
.info-card-detail-two img{
    /* align-self: start; */
    margin-left: 11px;
}
.info-card-detail-right .custom-span{
    font: normal 18px/21px "Roboto" !important;
    color: #2D2D2D;
   
}
.account-detail-add-more{
    font: normal 16px/21px "Roboto" !important;
    color: #4759A7;
    margin-left: 8px;
}
.class-flex{
    display: flex;
    align-self: flex-end;
}
.info-card-detail-right-justify{
    justify-content: space-between;
}
.mar-bot-12{
    margin-bottom: 12px;
}
.info-card .info-card-detail-right .plus-task{
    border: 1px solid #21BF73;
    background-color: #21BF730F;
    box-shadow: none;
    text-transform: capitalize;
    
    width: 80px;
    height: 33px;
    padding: 7px 8px 5px 8px;
}
.info-card .info-card-detail-right .plus-task span{
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73;
}
.account-detail-remove{
    font: normal 16px/21px "Roboto" !important;
    color: #6A6A6A;
}
.info-card-detail-right-last{
    padding-bottom: 12px;
    border-bottom: 1px solid #F2F4FB;
    margin-bottom: 20px;
}
.account-detail .add_dept_btn{
    
    margin-top: -10px !important;
   margin-bottom: 30px;
   background-color: #D1D1D14A !important;
}
.account-detail .add-note-card{
    box-shadow: 0px 2px 1px #0000000F !important;
}
.add-note-card .add-note-card-title{
    padding: 15px 20px;
    border-bottom: 1px solid hsl(227, 53%, 97%);
}
.add-note-card .add-note-card-title span{
    font: normal 18px/24px "Roboto" !important;
    color: #424242;
}

.add-note-card .MuiCardContent-root:last-child{
    padding: 12px 20px 32px 20px !important;
}
.account-details-input{
    display: flex;
}
.account-details-input .MuiFormControl-root{
    margin-top: 0px !important;
    width: 100%;
    margin-right: 20px;
}
.account-detail-container .account-expansion:last-child{
    margin-bottom: 0px !important;
}
/* .MuiDialog-paperWidthLg{
    width:1000px;
}
.dept-header{
    background: #F8F8FF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 50px;
    align-items: center;
    display: flex;
} */
.flex-div div{
    display: flex;
}
.edit-input{
    display: flex;
    align-items: flex-end;
}
/* .edit-input .MuiInputBase-root{
    height: 40px !important;
    width: 100%;
} */
.edit-input button{
    color: #21BF73 !important;
    font: normal 14px/19px "Roboto" !important;
    align-self: center;
    margin-left: 10px;
}
.info-card-wo-add{
    display: flex;
    align-items: center;
}
.flexSpan{
    display: flex;
    margin-right: 10px;
}
.hide-dept{
    display:none !important;
}
.hide-edit{
    display:none !important;
}
.pac-container{
    z-index: 1400 !important;
}
.nameFlex, .displayFlex{
    display: flex;
}

.acc-modal-err-msg {
    color: red;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 400;
    display: block;
}


.account_quick_view_dialog .MuiDialog-paperWidthLg{
    max-width: 948px !important;
    width: 948px;
}
.quickItem {
    margin-bottom: 17px;
}
.quickItemName {
    color: #929292;
    font: normal 16px/21px "Roboto" !important;
    margin-bottom: 10px;
}
.quickItemValue{
    display: flex;
}
.addrs-img{
    align-self: start;
}
.quickItemValue img{
   
    margin-right: 8px
}
.quickItemValue .quickItemText {
    color: #5B5B5B;
    font: normal 16px/21px "Roboto" !important;
}
.getDirectionBtn{
    align-self: center;
}
.getDirectionBtn .select_parent{
    padding: 0px;
    float: right;
}
.account_quick_view_dialog .dept-header {
    color: #424242;
    font: normal 18px/24px "Roboto" !important;
    padding: 13px 13px 13px 35px;
    margin-left: -20px;
    margin-right: -20px;
    background-color: #F8F8FF;
    margin-bottom: 26px;
}
.account-quick-exp-title{
    font: 600 16px/21px "Roboto" !important;
    color: #5B5B5B;
}
.account_quick_view_dialog .account-quick-exp {
    box-shadow: none !important;
    /* height: 40px; */
    margin-bottom: 15px !important;
    border-radius: 5px !important;
}
.plus-task-green{
    font: 600 16px/21px "Roboto" !important;
    color: #21BF73;
    margin-left: 15px;
}
.account_quick_view_dialog .MuiExpansionPanelDetails-root{
    padding: 0px !important;
    margin-top: 15px;
}
.account_quick_view_dialog  .MuiPaper-outlined{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    box-shadow: 0 10px 10px -10px rgba(39, 4, 15, 0.35);
    transition: all .4s;
}

.account_quick_view_dialog .MuiCardContent-root{
    padding: 15px 15px 5px 15px !important;
}
.qucikDepView {
    margin-bottom: 10px;
}
.qucikDepView img{
    margin-right: 8.3px;
}
.qucikDepViewValue {
    font: normal 14px/19px "Roboto" !important;
    align-self: center;
    color: #424242;
}
.account_quick_view_dialog .MuiExpansionPanelSummary-root{
    min-height: 44px;
    background-color: #F9F6FF;
    border-radius: 5px;
}
.account_quick_view_dialog .MuiExpansionPanelSummary-root.Mui-expanded{
    min-height: 44px !important;
    height: 44px;
}
.account_quick_view_dialog .add_user_header{
    margin-bottom: 15px !important;
}
.gm-style-mtc, .gm-svpc {
    display: none;
}
.qucikDepView .qucikDepViewValue:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Role Button */
.MuiInputBase-root.MuiOutlinedInput-root.role_filter.MuiInputBase-formControl{
    width:180px;
}

p.suspend_text{
    font: normal 16px/21px "Roboto";
    color: #8D8D8D;
    margin-bottom: 5px;
}
.edit_user_modal .form_input_field .error{
    bottom:-2px;
}
@media only screen and (max-width: 767px){
    .account_custom_upload{
        width: 100%;
    } 
    .info-card-detail-right, .info-card-wo-add ,.account-detail-header{
        display: block;
    }
    .flexSpan, .info-card .info-card-detail-right .info-card-sub-name{
        margin-bottom: 5px !important;
    }
    .account-detail-header .tableTitle{
        margin-bottom: 10px !important;
    }
    .account-detail-add-more{
        margin-left: 0px;
        margin-bottom: 5px;
    }
    .displayFlex{
        display: flex;
        margin-bottom: 8px;
    }
    
}
@media only screen and (max-width: 600px){
    .getDirectionBtn .select_parent{
        float: left;
        margin-bottom: 17px;
    }
}

@media (max-width: 480px){
    .MuiInputBase-root.MuiOutlinedInput-root.role_filter.MuiInputBase-formControl{
        width:140px;
    }
}
