.add_service_content .txt_field{
    padding:0 !important;
}
.add_service_content .label{
    font: normal 16px/21px "Roboto" !important;
    color: #333758 !important;
}
.add_service_content .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border : none;
}
.add_service_header h2{
    display: flex;
    align-items: center;
    width:100%
}
.add_service_header h2 span{
    font: normal 18px/24px "Roboto" !important;
    align-self: center;
    color: #424242;
}
.add_service_header h2 img {
    margin-right: 10px;
}

.add_user_header .MuiIconButton-root:hover{
    background: none !important;
}

.deleteServicesDialog .MuiDialog-paperWidthSm {
    max-width: 400px !important;
}
.deleteServicesDialog  .MuiDialogTitle-root{
    padding: 13px 10px 7px 10px;
}
.deleteServicesDialog h2{
    font: normal 16px/21px "Roboto";
    color: #505050;
}
.deleteServicesDialog .MuiDialogContent-root{
    padding: 0px 10px 8px 10px;
}
.deleteServicesDialog .MuiDialogContent-root p{
    color: #9C9C9C;
    font: normal 14px/19px "Roboto";
    margin: 0px;
}
.deleteServicesDialog .MuiDialogActions-root{
    padding: 0px 10px 17px 10px;
    justify-content: center;
}
.deleteServicesDialog .cancel,.deleteServicesDialog .delete{
    width: 130px;
    height: 35px;
    font: normal 16px/21px "Roboto"!important;
    text-transform: capitalize;
    color: #6A6A6A;
    border: 1px solid #BEBEBE;
}
.deleteServicesDialog .delete{
    border: none;
    background-color: #BF3354;
    color: #fff !important;
}
.deleteServicesDialog button.delete:hover {
    background-color: #9f1b3a !important;
}

.MuiInputBase-formControl.MuiInputBase-fullWidth.MuiInputBase-marginDense.MuiInputBase-root.MuiOutlinedInput-marginDense.MuiOutlinedInput-root.customSelect.select-service{
    border-color: rgba(0, 0, 0, 0.23);
}

.MuiInputBase-root.MuiOutlinedInput-root.customSelect.select-service.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense:hover{
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}
