 .adduserform {
    position: relative;
  }
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    padding: 0 4px !important;
  }
  .defaultSelect {
    border: 1px solid #e2e5f2;
    border-radius: 5px;
    height: 38px !important;
    font: normal 16px/21px "Roboto" !important;
    color: #424242;
    padding: 9px 14px;
  }
  
  .MuiCircularProgress-colorPrimary {
    color: #3f51b5;
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 99;
  }
  
  .add_company_dialog .add_user_header {
    background: #fcfcfe;
    padding: 16px 20px 16px 20px;
    margin-bottom: 5px;
  }
  .closeBtn{
    position: absolute !important;
    right: 0;
}



  /* .add_company_action .cancel_btn{
    width: 130px;
    color: #6A6A6A;
    height: 40px;
    border: 1px solid #BEBEBE;
    margin-right: 15px;
    font: normal 16px/21px "Roboto" !important;
    text-transform: capitalize;
}
.add_company_action .ok_btn{
  background-color: #21BF73;
  box-shadow: none;
  color: #FFFFFF;
  font: normal 16px/21px "Roboto" !important;
  text-transform: capitalize;
  height: 40px;
  min-width: 130px;
} */
/* .add_company_action{
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
} */
.add_user_header .MuiIconButton-root:hover{
  background: none !important;
}
/* .add_company_dialog .MuiDialog-paperWidthMd{
  max-width: 680px;
} */
  
.add_user_modal .form_input_field span.error{
  bottom:-2px;
}
.add_company_dialog .MuiDialog-paper {
  max-height:calc(100% - 64px);
}
@media only screen and (max-width: 767px){
  .add_company_dialog .MuiDialog-paper {
    margin: 0;
    max-width: 100%;
    /* removed border-radius */
  }
  .add_company_dialog .MuiDialog-paper{
    margin: 0 15px;
  }
}
@media (max-width: 480px){
  .add_company_dialog .MuiDialog-paper {
    margin: 0 5px;
  }
}