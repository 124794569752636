#header-navbar .MuiAppBar-colorPrimary {
    background-color: #fff;
    box-shadow: 0px 2px 2px #0000000D;
}
.header-logo img{
    width: 142px;
}
.grow{
    flex-grow: 1;
}
#header-navbar .MuiToolbar-gutters{
    padding: 0px 10px;
    height: 75px ;
}
#header-navbar .navbars{
    position: relative;
    margin-left: 15px;
    font: normal 16px/21px "Roboto";
    color: #303960;
    text-transform: capitalize;
    padding: 27px 10px 27px 10px;
    min-width: unset;
    text-decoration: none;
}
#more-menu .MuiMenuItem-root{
    font: normal 16px/21px "Roboto" !important;
    color: #303960 !important;
}
#header-navbar .add_task_btn{
    background-color: #21BF73;
    font: normal 16px/21px "Roboto";
    text-transform: capitalize;
    padding: 11px 35px 7px 62px;
    margin-right: 18px;
    box-shadow: none;
}
#header-navbar .add_task_btn:before{
    content: url(../../Assets/images/add_task.svg);
    position: absolute;
    left: 4px;
    height: 32px;
    top: 4px;
    width: 32px;
}
#header-navbar .user_icon:after{
    content: url(../../Assets/images/user_arrow.svg);
    position: absolute;
    right: 0;
    width: 11px;
    height: 6px;
    top: 12px;
    left: 62px;
}
#header-navbar .add_task_btn_mobile{
    font: normal 24px "Roboto";
    color: #fff;
    border-radius: 5px;
    background-color: #21BF73;
    margin-right: 8px;
    height: 39px;
    width: 40px;
}
.mobile_menu_dialog #header-navbar{
    display: contents;
}
.mobile-menu{
    padding: 10px;
}
.mobile_menu_dialog .user_icon {
    min-width: auto !important;
}
.mobile_menu_dialog .user_icon img{
    
    width: 32px;
    height: 32px;
}
.mobile-menu .MuiList-padding{
    padding: 0px !important;
}
.mobile-menu-list {
    height: 40px;
    background-color: #FBFBFB !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid #21BF73 !important;
}
.mobile-menu-list .MuiListItemText-root span{ 
    font: normal 16px/21px "Roboto";
    color: #424242;
}
.mobile-menu-expansion{
    background-color: #FBFBFB !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid #21BF73 !important;
    box-shadow: none !important;
}
.mobile-menu-expansion p{
    font: normal 16px/21px "Roboto" !important;
    color: #424242 !important;
}
.MuiExpansionPanel-root:before{
    opacity: 0 !important;
}


.header-profile .MuiTypography-root.user-name{
    color: #303960;
    font: normal 16px/21px "Roboto";
    margin-bottom: 5px;
}
.header-profile .MuiList-root .user-email{
    color: #B4B4B4;
    font: normal 12px "Roboto";
    margin-bottom: 21px;
}
.header-profile .MuiPopover-paper .MuiList-padding{
    padding: 16px;
    text-align: center;
    min-width: 234px;
}
.header-profile .MuiMenu-list a{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: center;
}
.accountimg{
    margin-right: 8px;
}
.header-profile .MuiList-root .hr-line{
    margin-bottom: 9px;
}
.MuiButtonBase-root.logout-button{
    background-color: #21BF730F;
    border: 1px solid #21BF73;
    font: normal 14px "Roboto";
    color: #21BF73;
    text-transform: inherit;
    padding: 5px 30px 5px;
    box-shadow: none;
}
.MuiButtonBase-root.logout-button:hover, .MuiButtonBase-root.logout-button:focus {
    background-color: #21BF730F;
    border: 1px solid #21BF73;
    box-shadow: none;

}
#header-navbar .navbars.active::before, #header-navbar .navbars:hover::before {
    content: "";
    background-color: #303960;
    height: 3px;
    width: 100%;
    /* z-index: 9999; */
    position: absolute;
    bottom: 0;
    border-radius: 19px 19px 0px 0px;
    left: 0;
}
.labelAsterisk, .upload-doc-label .labelAsterisk{
    color: #F20A0A;
}
.switch-container .MuiFormControlLabel-label{
    font: 500 14px/19px "Roboto" !important;
    margin: 0px 10px 0px 0px;
}
.switch-container {
    padding: 22px 18px 21px 15px;
    background: #FFF8F8;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.switch-container label{
    margin: 0px !important;
    display: flex;
}
.add_task_dialog .MuiDialog-paperWidthMd {
    max-width: 835px !important;
    width: 100% !important
}
.toDate, .fromDate{
    width: 100%;
}
.toDate .MuiInputBase-root, .fromDate .MuiInputBase-root{
    background-color: #F9F9F9;
    border-radius: 4px
}
.toDate input, .fromDate input{
    font: normal 14px/19px "Roboto" !important;
}
.labelEmergency{
    align-self: center;
}
.labelEmergency span{
    padding-left: 12px;
    font: normal 14px/19px "Roboto" !important;
}
.main_notification .MuiMenu-paper {
    max-width: 300px;
    overflow-y: hidden;
    padding: 12px 5px 12px 0;
    cursor:pointer;
}
.main_notification .notification_header{
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;
    margin-bottom: 21px;
}
.main_notification .notification_header .notification_header_name{
    color: #A2A2A2;
    font: normal 14px/19px "Roboto" !important;
}
.main_notification .notification_header .notification_header_count{
    background-color: #376ce263;
    height: 19px;
    width: 19px;
    border-radius: 10px;
    padding: 1px 6px;
    color: #376ce2;
    font: normal 14px/19px "Roboto"!important;
    align-self: flex-end;
}
.main_notification .notification_title{
    color: #303960;
    font: normal 14px/19px "Roboto" !important;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
}
.main_notification .notification_description{
    color: #ABABAB;
    font: normal 12px/15px "Roboto" !important;
    margin-bottom: 3px;
}
.main_notification .notification_hrs{
    color: #C4C4C4;
    font: normal 12px/15px "Roboto" !important;
   
}
.main_notification .notification_header .mark_all{
    color: #6C728A;
    font: normal 14px/19px "Roboto" !important;
    align-self: flex-end;
    cursor: pointer;
}
.main_notification .notification_card_div {
    margin: 0px 15px 10px 15px;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;
}
/* .main_notification .notification_card_div:nth-child(1){
    background-color: red;:nth-child(odd)
} */
.read_card::before {
    content: "";
    background-color: #376ce2;
    height: 100%;
    width: 3px;
    position: absolute;
    bottom: 0;
    border-radius: 0px 4px 4px 0px;
    left: -15px;
}
.main_notification .MuiList-padding{
    padding-bottom: 0px;
    padding-top: 0px;
}
.notification_read_check{
    padding: 0px 5px !important;
}
.notification_clear{
    padding: 4px 5px !important;
}
.notification_title_btns{
    display: flex;
    align-items: start;
}
.profile-pic{
    width: 44px;
    height: 44px;
    box-shadow: 0px 3px 6px #00000012;
    border: 1px solid #E5E5E5;
    opacity: 1;
    border-radius: 50px;
    object-fit: cover;
}


.task_assignment_dialog .add_user_header {
    padding: 25px !important;
    background-color: #ffffff !important;
    margin-bottom: 50px !important;
}
.task_assignment_dialog .MuiDialog-paperWidthSm{
    max-width: 844px !important;
    width: 844px !important;
}
.task-img-width{
    width: 100%;
}
.tasking-assign-right h1 {
    margin-bottom: 7px;
    color: #3A3A3A;
    font: bold 28px/37px "Roboto"!important;
}
.tasking-assign-right p{
    color: #303960;
    font: normal 19px/25px "Roboto"!important;
    margin-bottom: 7px;
    display: flex;
}
.tasking-assign-right p img{
    margin-right: 7.5px;
    align-self: start;
}
.tasking-assign-right span{
    color: #7E7E7E;
    font: normal 16px/21px "Roboto"!important;
    margin-bottom: 38px;
    display: block;
}
.task_assign_action{
    justify-content: left;
    margin-top: 0px;
}
.task_assign_model_container{
    margin-bottom: 60px;
}
.task_assignment_header  h2 img{
    margin-right: 25px !important;
}
.task_assign_errror_msg{
color: #D27700;
/* height: 30px; */
background-color: #FFF6DC;
border-radius: 5px;
margin-bottom: 10px;
width: 100%;
max-width: 389px;
padding: 8px 10px 7px 10px;
font: normal 12px/14px "Roboto"!important;
}
.task_assign_errror_msg img{
    margin-right: 5px;
}

#header-navbar .add_task_btn_mobile{
    padding:0;
}

#header-navbar .add_task_btn:before{
    z-index:9;
}
.mobile-menu > nav > div.MuiListItem-root{
    padding:0;
    height:40px;
}
.mobile-menu > nav > div.MuiListItem-root a.navbars{
    padding:10px 15px 9px;
    font: 500 16px/21px "Roboto";
    color: #424242;
    width:100%;
}
.mobile_menu_dialog .user_icon img{
    margin:0 10px;
}
.MuiButtonBase-root.logout-button{
    padding: 4px 30px;
    font: normal 14px/19px "Roboto";
    color: #21BF73;
}
#header-navbar .add_task_btn{
    padding: 9px 35px 9px 62px;
}
.mobile-profile >  .MuiPopover-paper{
    top:50% !important;
    left:50% !important;
    transform: translate(-50%, -50%) !important;
}
.header-profile.mobile-profile .MuiPopover-paper .MuiList-padding{
    min-width: 285px;
}
.header-profile.mobile-profile .MuiMenu-list a{
    justify-content: center;
}
header .header-logo:hover{
    background: none;;
}
#header-navbar .add_task_btn, #header-navbar .add_task_btn_mobile{
    background: transparent linear-gradient(180deg, #6D99FC 0%, #376CE2 100%) 0% 0% no-repeat padding-box;
}
#header-navbar .add_task_btn:hover, #header-navbar .add_task_btn_mobile:hover{
box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    background-color: #d5d5d5;
}
.mobile-menu-list{
    border-bottom: 1px solid #376CE2 !important;
}
#header-navbar .navbars.active::before, #header-navbar .navbars:hover::before{
    background: #376CE2;
}
.user_icon.user_profile{
    margin-left:0;
}
.infinite_scroll::-webkit-scrollbar {
    width: 7px;
    padding-right:2px;
}
.infinite_scroll::-webkit-scrollbar-track {
    padding-right:2px;
}
.infinite_scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0,0,0,0.3); 
} 
.infinite_scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.5); 
} 
.company-logo {
    width: 100%;
    height: 100%;
    padding: 5px;
    object-fit: contain;
    max-width: 142px; 
    max-height: 75px;
}

@media only screen and (max-width: 768px){
    .header-logo img{
        width: 104px;
    }
    .login_form_div{
        padding: 17px 10px;
    }
    .login_form .logo{
        width: 140px;
    }
    .login_form .login-header{
        font: bold 24px/31px "Roboto";
    }
}
@media only screen and (max-width: 1130px){
    #header-navbar .navbars, #header-navbar .user_icon, #header-navbar .add_task_btn{
        display: none;
    }
    #header-navbar .MuiToolbar-gutters{
        height: 67px;
        background-color: #FCFCFE;
    }
    
}
@media only screen and (min-width: 1130px){
    #header-navbar .add_task_btn_mobile, #header-navbar .mobile-menu-icon{
        display: none;
    }

}