.johnson_logo {
    margin: 23px 0px 20px 30px;
    max-width: 300px;
}
.company_detail_right_item_value_btn a{
    color:#424242;
}
.company_detail_left_header {
    display: flex;
    padding: 18px 30px;
    background-color: #FCFCFC;
}
.company_detail_left_header img {
    margin-right: 8.5px;
}
.company_detail_left_header h1 {
    margin: 0px;
    color: #424242;
    font: bold 26px/34px "Roboto";
}
.company_detail_left_content {
    padding: 30px;
    background-color: white;
}
.company_detail_left_item p {
    color: #303960;
    font: 500 16px/21px "Roboto";
    margin-bottom: 8px;
}
.company_detail_left_item_value img{
    margin-right: 7.5px;
    align-self: start;
    margin-top: 3px
}
.company_detail_left_item_value span{
    color: #2D2D2D;
    font: normal 16px/21px "Roboto";
}
.company_detail_left_item{
    margin-bottom: 20px;
}
.company_detail_main{
    border-radius: 5px;
}
.company_detail_right_content{
    padding: 23px 18px;
}
.company_detail_right_header{
    padding: 21px 20px;
    background-color: #FCFCFC;
}
.company_detail_right_header h1{
    color: #424242;
    font: bold 21px/28px "Roboto";
    margin: 0px;
    
    
}
.company_detail_right_item h2 {
    color: #424242;
    font: bold 16px/21px "Roboto";
    margin: 0px;
    padding: 16px 19px;
    background-color: #F2F2F2;
}
.company_detail_right_item h2 span {
    color: #2D2D2D;
    margin-left: 9px;
    font: normal 16px/21px "Roboto";
}
.company_detail_right_item_value {
    padding: 11px 20px 0px 20px;
}
.company_detail_right_item_values p{
    color: #303960;
    font: 500 16px/21px "Roboto";
    margin: 0px;
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.company_detail_right_item_values p span{
    color: #2D2D2D;
    margin-left: 5px;
    font: normal 16px/21px "Roboto";
}
.company_detail_right_item_values {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.company_detail_right_item_value_common{
    padding-bottom: 8px;
    margin-bottom: 20.5px;
    border-bottom: 1px solid #F2F2F2;
}
.company_detail_right_item {
    border: 1px solid #F2F2F2;
}
.company_detail_right_item_value_two.company_detail_right_item_value_common:last-child {
    border: none;
    margin: 0px;
}
.company_detail_right_item_value_btn{
    color: #424242;
    font: normal 16px/21px "Roboto";
    width: 86px;
    height: 28px;
    text-align: center;
    border-radius: 5px;
    background-color: #F6F6F6;
    padding: 5px 0px 4px 0px;
}
.company_detail_right_item_value_btn img{
    margin-bottom: 3px;
}
.company-detail-table{
    margin: 0px;
}

.company_detail_left_item_value{
    display: flex;
}
.company_detail_left_item_value span{
    overflow-wrap: break-word;
    word-break: break-word;
}
.invite_contact_header{
    background-color: #FBFBFB;
    margin: 25px -20px !important;
    color: #424242;
    font: 500 18px/22px "Roboto" !important;
    margin-bottom: 8px;
    padding: 10px 20px;
}

.add_company_modal .form_input_field span.error{
    bottom: -2px;
}
.add_company_modal .form_input_field.company_form_field span.error{
    bottom: -17px;
}
.trail_field {
    background: #f9f9f9 0 0 no-repeat padding-box;
    border-radius: 5px;
    padding: 13px 15px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trail_field .ant-picker {
    background: transparent;
}