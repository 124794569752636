.cms_container {
    background-color: #ffffff;
    padding: 20px;
    justify-content: end;
  }
  
  .cms_container .cms_label {
    color: #424242;
    font-size: 24px;
  }
 
  .cms_container .module_detail {
    position: relative;
    width: 100%;
  }
  
  .cms_container .text_input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    color: #aaaaaa;
    border: 1px solid #e2e5f2;
  }
  
  .cms_container .module_detail img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
  }
  
  .cms_container .text_input_value {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    color: black;
    border: 1px solid #e2e5f2;
  }
  .formControlLabel{
    height:40px;
    float: right;
  }
  .cms_container .MuiFormControlLabel-labelPlacementStart{
    margin-right: 0px !important;
  }
  .cms-label{
    color: #303960;
    font: 500 16px / 21px "Roboto";
}

.cms-item {
  max-width: 33% !important;
}