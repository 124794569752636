.calender-page{
    padding: 0px 12px;
}
.calender-page .fc .fc-toolbar.fc-header-toolbar {
    margin: 0px;
    padding: 10px 0px;
}

.calender-page  .fc .fc-button-primary:not(:disabled){
    color: #5B5B5B !important;
    background: #F2F4FB !important;
    border: 1px solid #E2E5F2 !important;
    text-transform: capitalize;
    width: 75px;
    height: 30px;
    padding: 0px;
    font: normal 14px/19px "Roboto"!important;

}

.calender-page .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active{
    color: #5B5B5B !important;
    background: #FFFFFF !important;
    box-shadow: 0px 4px 12px #0000001C !important;
    text-transform: capitalize;
    width: 75px;
    border: none !important;
}
.calender-page .fc .fc-toolbar-title{
    font: bold 24px/31px "Roboto" !important;
    color: #303960 !important;
    margin-top: 5px;
}
.calender-page .ant-picker-calendar-mini{
    /* margin-top: 56px; */
    /* padding-right: 55px; */
    border-radius: 0px !important;
    margin-bottom: 30px;
}
.calender-page .fc-theme-standard .fc-scrollgrid{
    border: none !important;
}
.calender-page .fc .fc-scrollgrid-section > td{
    border: none !important;
}
.calender-page .fc-theme-standard td, .fc-theme-standard th{
    border: 1px dashed #F1F1F1 !important;
    border-right-style: solid !important;
}
.calender-page .fc-day-other {
    background-color: #FCFCFC !important;
}
.calender-page .fc .fc-daygrid-day.fc-day-today {
    background-color: #F2F6FF;
    border-bottom: 2px solid #376CE2 !important;
}
.calender-page th.fc-col-header-cell.fc-day {
    background-color: #FCFCFC;
}
.calender-page .fc .fc-col-header-cell-cushion{
    font: normal 14px/19px "Roboto"!important;
    color: #5B5B5B;
    padding: 8px 0px 3px 0px;
}
.calender-page .fc-day-today .fc-daygrid-day-number{
    color: #376CE2 !important;
}
.calender-page .fc-view-harness{
    background-color: white;
    border-radius: 5px;
}
/* .calender-page .fc-header-toolbar .fc-prev-button,.fc-header-toolbar .fc-next-button{
display: none !important;
} */


.calender-page .ant-picker-calendar-mode-switch{
    display: none !important;
}
.calender-page .fc-daygrid-day-number {
    text-align: left !important;
    font: 400 16px/21px "Roboto" !important;
    letter-spacing: 0px !important;
    color: #424242 !important;
    padding: 10px !important;
  }
  .calender-page .fc-event-title{
    font: normal 10px/13px "Roboto"!important;
    text-overflow: ellipsis;
    color: #5B5B5B;

  }
  .calender-page .uncovered {
    border-left: 2px solid #D24856;
    background-color: #FFD0BE;
    color: #D24856;
  }
  .calender-page .inprogress{
    border-left: 2px solid #69F3B1;
    background-color: #F6FFFB;
    color: #69F3B1;
  }
  .calender-page .unassigned,.calender-page .pending{
    border-left: 2px solid #617FFF;
    background-color: #E1E6F9;
    color: #617FFF;
  }
  .calender-page .assigned{
    border-left: 2px solid #303960;
    background-color: #CCD6FF;
    color: #303960;
  }
  .calender-page .completed{
    border-left: 2px solid #21BF73;
    background-color: #9be5c2;
    color: #21BF73;
  }
  .calender-page .cancelled{
    border-left: 2px solid #888888;
    background-color: #E2E5F2;
    color: #888888;
  }
  .calender-page .fc .fc-daygrid-event, .calender-page .fc .fc-timegrid-event{
    margin-bottom: 1px;
    border-radius: 2px;
    padding: 4px 7px 0px 7px;
  }
  .calender-page .fc .fc-view-harness-active > .fc-view{
    padding: 20px 17px 0px 13px;
  }
  .calender-page .fc-daygrid-event-dot{
    display: none;
  }

  .cal-task-selected-title {
    font: 600 18px/24px "Roboto" !important;
    margin-bottom: 13.5px;
    color: #424242;
    padding: 15px;
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cal-task-selected-title .MuiFormControl-fullWidth{
    max-width: 133px;
}
.cal-task-selected-status {
    font: 500 14px/19px "Roboto" !important;
    margin-bottom: 6px;
    display: inline-block;
    color: #424242;
    padding: 4px 21px;
    border-radius: 5px;
    border: none !important;
}
.cal-task-selected-text {
    color: #424242;
    font: 500 16px/21px "Roboto" !important;
    margin-bottom: 10px;
    cursor: pointer;
}
.cal-task-selected-assigne {
    color: #7B7B7B;
    font: normal 14px/19px "Roboto" !important;
    margin-bottom: 10px;
}
.cal-task-selected-assigne span{
    color: #434343;
    margin-left: 8px;
}
.cal-task-selected-date {
    color: #376CE2;
    font: normal 14px/19px "Roboto" !important;
    padding-bottom: 16.5px;
    margin-bottom: 16.5px;
    border-bottom: 1px solid #F5F5F5;
}
.cal-task-selected-btn {
    background-color: #2F3D7B !important;
    width: 100%;
    text-transform: capitalize !important;
    color: #FFFFFF !important;
    font: normal 14px/19px "Roboto" !important;
    height: 38px;
}

.calender-left-container{
    background-color: #ffff;   
}
.calender-left-container .cal-task-selected{
    padding: 0px 15px 15px;
}
.calender-left-grid{
    background-color: #fff;
    margin-top: 56px !important;
    border-radius: 5px;
}
.calender-page .inputHeight, .calender-page .MuiInputBase-root{
    font: normal 14px/19px "Roboto" !important;
    color: #505050;
    height: 36px !important;
    padding: 13px;
    padding-right: 26px;
}

.calender-page .ant-picker-calendar-mini .ant-picker-content th, .calender-page .ant-picker-cell .ant-picker-cell-inner{
    font: normal 14px/19px "Roboto" !important;
    /* color: #E2E5F2; */
}
.calender-page .ant-picker-cell-in-view, .calender-page .ant-picker-calendar-mini .ant-picker-content th{
    color: #5B5B5B !important;
}
.calender-page .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.calender-page .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.calender-page .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
background: transparent;
color: #21BF73;
}
.calender-page .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.calender-page .ant-picker-calendar .ant-picker-panel{
    border: none;
}
.calender-page .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector{
    font: normal 14px/19px "Roboto" !important;
    padding-top: 2px;
}
.calender-page .fc .fc-daygrid-more-link{
    font: normal 14px/16px "Roboto" !important;
}
.calender-page .fc-v-event,.calender-page .fc-h-event{
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
}
.calender-page .fc .fc-timegrid-slot-label-cushion,.calender-page .fc .fc-timegrid-axis-cushion{
    font: normal 14px/19px "Roboto"!important;
    color: #5B5B5B;
}
.calender-page .fc .fc-timegrid-slot-label,.calender-page .fc .fc-timegrid-axis{
    background-color: #FCFCFC;
}
.calender-left-grid .MuiSelect-select:focus{
    background-color: white !important;
}
.calender-left-grid .MuiSelect-outlined.MuiSelect-outlined{
padding: 0px !important;
}
.calender-left-grid .dashboard_ant_select .ant-select-selector {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D3D3D3 !important;
    border-radius: 5px !important;
    opacity: 1;
    width: 133px !important;
    font: 500 14px/19px "Roboto" !important;
    padding: 13px 15px 13px 15px !important;
    height: 36px !important;
    align-items: center;
}
.fc-daygrid-day {
    cursor: pointer;
  }
  /* .fc-daygrid-event-dot {
    margin: 0 4px !important;
    box-sizing: content-box !important;
    width: 2px !important;
    height: 17px !important;
    background-color: #4759A7;
    border-radius: 2px 0px 0px 2px !important;
    border: none !important;
} */
/* .fc-daygrid-event{
    background: #E1E6F9 0% 0% no-repeat padding-box;
    border-radius: 2px;
} */

/* events calender */
.events_calender .fc-daygrid-event-harness > card{
    position: absolute;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0px 3px 16px #00000029;
    left: 50%;
    transform: translate(-50%, 0px);
    min-width: 250px;
    padding: 10px;
    border-radius: 5px;
}
.events_calender .fc-scrollgrid-sync-table > tbody > tr td:first-child .fc-daygrid-event-harness > card{
    left:0;
    transform: none;
}
.events_calender .fc-scrollgrid-sync-table > tbody > tr td:last-child .fc-daygrid-event-harness > card{
    right:0;
    left:unset;
    transform: none;
}

.calender-page .fc .fc-daygrid-event, .calender-page .fc .fc-timegrid-event{
    padding: 2px 7px;
}
.event-detail-section{
    margin-bottom: 10px;
}
.events_calender .fc-daygrid-event-harness > card cardcontent .event-detail-section:last-child{
    margin-bottom: 0;
}
.event-detail-section typography:first-child{
    font: bold 16px/19px "Roboto" !important;
} 
.event-detail-section typography{
    font: normal 16px/19px "Roboto" !important;
    color: #303960;
} 
.events_detail_modal .MuiDialog-paperWidthSm{
    padding: 19px 0 17px;
    min-width: 409px;
    max-width:800px;
    box-shadow: 0px 5px 36px #0000000D;
    border: 1px solid #FCFCFC;
    border-radius: 10px;
}
.events_detail_modal .events_detail_title{
    padding: 0;
}
.events_detail_modal .events_detail_content{
    padding: 0 !important;
}
.events_detail_header{
  padding: 0 15px;
  margin-bottom: 18px;
}
.events_detail_name{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:12px;
}
.events_detail_name > div{
  display: flex;
  align-items: center;
}
.events_detail_name h4{ 
    font: bold 16px/19px "Roboto";
    color: #424242;
    margin-right:9px;
    margin-bottom: 0;
}
.events_detail_name img{
  width:12px;
}
.events_detail_name span{ 
    background: #FBFFFD;
    border-radius: 5px;
    font: 500 12px/14px "Roboto";
    color: #69F3B1;
    padding: 6px 9px;
    margin-right:9px;
}
.events_detail_content h5.event_time{
    font: normal 14px/17px "Roboto";
    color: #424242;
    margin-bottom: 16px;
}
.events_detail_content h6.sales_rep_heading{
    font: 500 14px/17px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    background-color: #FCFCFC;
    padding:9px 15px;
    margin-bottom: 11px;
}
.sales_rep_details{
  padding:0 15px;
}
.sales_rep_details .events_detail_section{
  margin-bottom: 20px;
}
.sales_rep_details .events_detail_section:last-child{
  margin-bottom:0;
}
.events_detail_section.sales_rep_email{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
.events_detail_section img{
  margin-right:8px;
}
.events_detail_section span, .events_detail_section span a{
  font: normal 14px/17px "Roboto";
  color: #424242;
}
/* Calendar */
.fc-scrollgrid .fc-scrollgrid-section-header .fc-scroller {
    overflow: hidden !important;
}
.fc-scrollgrid .fc-scrollgrid-section-body {
    display: none;
}
.fc-scrollgrid .fc-scrollgrid-section-liquid {
    display: contents;
}
.events_detail_name .events_type_unassigned{
    color:#617FFF;
    background-color: rgb(97, 127, 255, 0.3);
}
.events_detail_name .events_type_pending{
    color:#617FFF;
    background-color: rgb(97, 127, 255, 0.3);
}
.events_detail_name .events_type_assigned{
    color:#303960;
    background-color: rgb(48, 57, 96, 0.3);
}
.events_detail_name .events_type_inprogress{
    color:#33BE9D;
    background-color: rgb(51, 190, 157, 0.3);
}
.events_detail_name .events_type_completed{
    color:#21BF73;
    background-color: rgb(33, 191, 115, 0.3);
}
.events_detail_name .events_type_uncovered{
    color:#D24856;
    background-color: rgb(210, 72, 86, 0.3);
}
.events_detail_name .events_type_cancelled{
    color:#888888;
    background-color: rgb(136, 136, 136, 0.3);
}
@media only screen and (max-width:767px){
    .calender-page .fc .fc-toolbar-title{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .calender-page .fc .fc-toolbar.fc-header-toolbar{
        display: block;
    }
    .calender-page {
        padding: 0px 20px;
    }
    .calender-page .fc .fc-view-harness-active > .fc-view {
        padding: 10px;
    }
}

@media (max-width: 600px){
    .events_calender .fc-daygrid-event-harness > card{
        width:200px
    }
  .events_detail_modal .MuiDialog-paperWidthSm{
    margin:0 15px;
  }
  .events_detail_name > div{
    flex-wrap: wrap;
  }
}

@media (max-width:480px){
  .events_detail_modal .MuiDialog-paperWidthSm{
    width:100%;
    min-width: auto;
    margin:0 5px;
  }
  .events_detail_name h4{
    width:100%;
  }
  .events_detail_name span{
    margin-top:10px;
  }
  .events_detail_name{
    align-items: flex-start;
  }
  .events_detail_name img{
    margin-top : 4px;
  }
}