.err-msg{
    margin-left: 14px;
    color: red;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}

.signup-body-bg-color{
    background-color: #f2f4fb;
    min-height : 653px;
}
.signup-root{
    max-width: 820px;
    margin: auto;
  }
  .bullet{
    display: 'inline-block';
    margin: '0 2px';
    transform: 'scale(0.8)';
  }
  .title  {
    font-size: 14;
  }
  .pos {
    margin-bottom: 12;
  } 
  .card-header{
    background: transparent linear-gradient(101deg, #1E3C72 0%, #2A5298 100%) 0% 0% no-repeat padding-box;
  }
  .card-header h1,h2,h3,p{
    color: rgb(248, 244, 244);
    margin: 0px;
}
.pt-10{
    padding: 20px !important;
}

.loginLabel{
    font: normal 16px/21px "Roboto"  !important;
    margin-bottom: 8px;
    color: #333758 !important;
    margin-top: 10px;
}

.drop-zone-area {
    height: 100%;
}
.buttonGroup{
    float : right;
    padding: 0;
    margin-top: 10px;
}
.signupBtn{
    width: none !important;
    padding: 10px !important;
    text-transform: none !important;
    font: normal 16px/21px "Roboto" !important;
    background-color: #21BF73 !important;
    box-shadow: none !important;
    margin-top: 20px !important;
}
.cancelBtn{
    width: none !important;
    padding: 10px !important;
    text-transform: none !important;
    font: normal 16px/21px "Roboto" !important;
    background-color: #eae7e7!important;
    box-shadow: none !important;
    margin-top: 20px !important;
    margin: 5px;
}
.cancelBtn .MuiButton-label{
    color: black;
}
.signup-body{
    margin-top: 30px;
}

.signup-logo{
    display: flex;
    margin-bottom: 20px;
}

.header-content{
    padding: 20px 35px;
}

.signup-name{
    font: normal 16px "Roboto" !important;
    margin-bottom: 10px;
}

.pragraph-text{
    font: bold 22px "Roboto" !important;
}
.MuiDropzoneArea-root{
    border: 1px dashed #E2E5F2 !important;
    min-height: 118px !important;
    background-color: #FCFCFC;
    border-radius: 5px;
}

.dropzone-icon{
    text-align: center;
    padding: 13px;
}
.dropzone-icon img{
    margin-bottom: 6px;
}
.dropzone-inst{
    color: #B5B5B5;
    font: normal 14px "Roboto";
}

.dropzone-paragraph{
    color: #303960;
    font: normal 18px "Roboto" !important;
    margin-bottom: 7px; 
}
.dropzone-paragraph span {
    color: #3C57CB;
}
.cancel-button{
    border: 1px solid #BEBEBE !important;
    border-radius: 5px !important;
    color: #6A6A6A;
    background-color: #fff !important;
    padding: 12px 39px !important;
    margin-top: 0 !important;
}

.success-button{
    margin-left: 15px !important;
    padding: 12px !important;
    margin-top: 0 !important;
}

.success-button .MuiButton-label{
    font: normal 16px/17px "Roboto" !important;
}
.cancel-button .MuiButton-label{
    font: normal 16px/16px "Roboto" !important;
    color: #6A6A6A;
}

.MuiPaper-elevation1{
    box-shadow: 0 10px 10px -10px rgba(39, 4, 15, 0.35) !important;
}

.reset_password_section .card-header {
    background: transparent linear-gradient(270deg, #789DEF 0%, #233687 100%) 0% 0% no-repeat padding-box;
}
.join_btn{
background: transparent linear-gradient(180deg,#6d99fc,#376ce2) 0 0 no-repeat!important;
}
.join_btn:hover {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12)!important;
    background-color: #d5d5d5!important;
}
@media (max-width: 767px) {
    .body-bg-color .signup-body {
        padding-left: 5px;
        padding-right: 5px;
    }
    .header-content {
        padding: 20px 20px;
    }
}