.tab-card-container .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none !important;
}
.tab-card-container .ant-tabs-tab {
  margin: 0 !important;
  border: none !important;
  border-radius: 5px 5px 0px 0px !important;
  height: 38px;
  width: 160px;
  text-align: center;
  background-color: transparent !important;
  font: 500 16px/21px "Roboto" !important;
  box-shadow: none;
}
.tab-card-container .ant-tabs-tab .ant-tabs-tab-btn {
  color: #646670 !important;
  width: 100%;
  font-weight: 600;
}
.tab-card-container .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  background: #fff !important;
}
.tab-card-container .ant-tabs-tab .ant-tabs-tab-btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
}

.tab-card-container .ant-tabs > .ant-tabs-nav {
  margin: 0px !important;
}
.tab-card-container .ant-tabs {
  box-shadow: 0px 2px 1px #0000000f;
  border-radius: 0px 5px 5px 5px;
  margin-bottom: 30px;
}
.tab-card-container .ant-tabs-content-holder {
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 0px 5px 5px 5px;
}
.tab-card-container .ant-tabs-content-holder .tab-container-title {
  color: #424242;
  font: 500 18px/24px "Roboto" !important;
  padding: 0px 10px 10px 12px;
  border-bottom: 1px solid #f2f4fb;
  margin-bottom: 12px;
}
