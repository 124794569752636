.dept_flex{
    max-width: 855px;
    margin: 0px auto;
}
.dept-layout{
    background:#FFFFFF !important;
    padding: 15px 15px;
}
.header-dept{
    display: flex;
    background: #EEEEEE;
    border-radius: 5px;
    justify-content: space-between;
    padding: 13px 20px;
    margin: 0px 10px;
}
.dept_title{
    font: bold 18px/24px "Roboto";
    color: #424242;
}
.add_dept_name{
    font: Bold 16px/21px "Roboto";
    color: #376CE2;
}
.dept_name_grid{
    background: #FFFFFF;
    box-shadow: 0px 6px 10px #00000008;
    border: 1px solid #F2F2F2;
    border-radius: 10px;
    display: flex;
    padding: 12px 25px !important;
    margin: 15px 15px ;
    height: 45px;
 }
 .dept_name_text{
     text-align: left;
     font: normal 16px/21px "Roboto";
     color: #424242;
     width: 75%;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
}
.dept_name_grid span{
    color:#424242 !important;
}
.dept_edit_icon{
    width: 16px;
    height: 14px;
    /* margin-left: 49px; */
}
.dept_del_icon{
    width: 14px;
    height: 17px;
    margin-left: 15px;
}
.dept_name_edit_del{
    display: flex;
}
.searchbar{
     color: '#929292';
     font: normal 14px/19px "Roboto"; 
     background: '#F3F3F3';
     border-radius: 5px 
}
.add_btn{
    right: 0;
    border: 1px solid #21BF73 !important;
    background-color: #21BF730F !important;
    box-shadow: none;
    font: normal 16px/21px "Roboto" !important;
    color: #21BF73 !important;
    width: 291px;
    height: 41px;
    margin-top: 10px !important;
}
.txt_field{
    width: 291px;
    height: 38px;
    border: 1px solid #E2E5F2 !important;
    border-radius: 5px;
    padding: 5px 12px !important;
}
.add_btn_labels{
    padding: 8px 9px;
    text-align: left;
    font: normal 15px/20px "Roboto" !important;
}

.deleteServiceDialog .MuiDialog-paperWidthSm {
    max-width: 309px !important;
}
.deleteServiceDialog  .MuiDialogTitle-root{
    padding: 13px 10px 7px 10px;
}
.deleteServiceDialog h2{
    font: normal 16px/21px "Roboto";
    color: #505050;
}
.deleteServiceDialog .MuiDialogContent-root{
    padding: 0px 10px 8px 10px;
}
.deleteServiceDialog .MuiDialogContent-root p{
    color: #9C9C9C;
    font: normal 14px/19px "Roboto";
    margin: 0px;
}
.deleteServiceDialog .MuiDialogActions-root{
    padding: 0px 10px 17px 10px;
    justify-content: center;
}
.deleteServiceDialog .cancel,.deleteServiceDialog .delete{
    width: 130px;
    height: 35px;
    font: normal 16px/21px "Roboto"!important;
    text-transform: capitalize;
    color: #6A6A6A;
    border: 1px solid #BEBEBE;
}
.deleteServiceDialog .delete{
    border: none;
    background-color: #BF3354;
    color: #fff !important;
}

.dept_name input{
    padding:0;
}
.dept_name_grid{
    align-items: center;
}
.dept_name_edit_del > div{
    display: inline-flex;
    align-items: center;
}