.body-bg-color.billing-page {
    padding-bottom: 0;
}
.billing_container {
    display: flex;
}
.billing-page header {
    position: relative;
}
.billing_sidebar {
    width: 271px;
    min-height: calc(100vh - 75px);
    background: #FFFFFF;
    padding: 28px 18px;
}
.billing_page {
    width: calc( 100% - 271px );
    padding: 28px 70px 16px;
}
.billing_list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}
.billing_list_item {
    margin-bottom: 8px;
}
.configure_btn {
    font: normal 16px/21px "Roboto";
    color: #5B5B5B;
    display: inline-block;
    background: transparent;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 11px 17px;
    width: 100%;
    text-align: left;
}
.configure_btn.active, .configure_btn:hover {
    background: #FBFBFB;
    border: none;
    outline: none;
    border-radius: 5px;
    font: normal 16px/21px "Roboto";
    color: #4759A7;
    padding: 11px 17px;
    width: 100%;
    }
/* SideBar */
.settings_sidebar{
    padding: 27px 20px 27px 21px;
}
.sidebar_heading{
    font: normal 600 24px/31px "Roboto";
    letter-spacing: 0px;
    color: #303960;
    padding-left: 14px;
    margin-bottom: 25px;
}
.settings_sidebar .configure_btn{
    padding: 9px 10px 9px 12px;
    color: #424242;
    display: flex;
    align-items: center;
}
.settings_sidebar .configure_btn.active,
.settings_sidebar .configure_btn:hover{
    color: #295195;
}
.settings_sidebar .configure_btn > span{
    border: 1px solid #E5E5E5;
    width:44px;
    height:44px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
}
.settings_sidebar .configure_btn > span img.blue-img{
    position: absolute;
    opacity: 0;
}
.settings_sidebar .configure_btn > span.manage-profile-images img{
    width: 19px;
}
.settings_sidebar .configure_btn > span.passoword-images img{
    width: 14px;
}
.settings_sidebar .configure_btn > span.notication-images img{
    width: 16px;
}
.settings_sidebar .configure_btn:hover > span img.blue-img, .settings_sidebar .configure_btn.active > span img.blue-img{
    opacity: 1;
}
/* SideBar */

/* Settings Content */
.settings_page{
    padding: 60px 92px 51px;
}
.settings_page > div{
    max-width: 830px;
    margin-left:0;
}
/* Settings Content */

/* Notifications */
.notifications-container{
    background: #FFFFFF;
    box-shadow: 0px 3px 22px #0000001A;
}
.notifications-header .MuiTableCell-head{
    padding: 16px 28px 20px;
    background: #FCFCFE;
    border-bottom: 0;
}
.notifications-header h4{
    font: normal 600 24px/31px "Roboto";
    letter-spacing: 0px;
    color: #424242;
    margin-bottom: 0;
}
.notifications-header h5{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    color: #333758;
    margin-bottom: 0;
}
.notifications-container .MuiFormControlLabel-labelPlacementStart{
    margin-left:0;
}
.notifications-container .MuiTableCell-body{
    padding:8px 28px;
    border-bottom: none;
}
.notifications-container .MuiTableCell-body h6{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    color: #858585;
    margin-bottom: 0;
}
.notifications-container .MuiTableCell-body p{
    font: 500 16px/21px "Roboto";
    letter-spacing: 0px;
    color:#333758;
    margin-bottom: 0;
}
.notifications-container .notifications-title.MuiTableCell-body{
    padding: 28px 28px 6px;
}
.notifications-container .add_company_action{
    padding: 25px 32px 31px;
    margin-top:0;
}
.notifications-container .notifications-body .MuiTableRow-root:first-child .MuiTableCell-body{
    padding-top: 17px;
}
.notifications-container .notifications-body .MuiTableRow-root:last-child .MuiTableCell-body{
    padding-bottom: 31px;
}
/* Notifications */

/* Password & Security */
.password_header{
    background-color: #FCFCFE;
    padding: 16px 28px 20px;
}
.password_header h5{
    font: 500 24px/31px "Roboto"; 
    color: #424242;
    letter-spacing: 0;
    margin-bottom: 0;  
}
.password_body{
    padding: 18px 32px 33px 28px;
}
.password_rules{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 22px;
}
.password_rules h6{
    font: normal 16px/21px "Roboto";
    color: #5B5B5B;
    letter-spacing: 0;
    margin-bottom: 12px;
}
.password_rules ul{
    padding:0;
    margin-bottom: 0;
    list-style: none;
}
.password_rules li{
    font: normal 16px/26px "Roboto";
    color: #929292;
    letter-spacing: 0;
    margin-bottom: 3px;
}
.password_rules ul li:last-child{
    margin-bottom: 0;
}
.notifications-container.password-container .add_company_action{
    padding:0;
    margin-top: 6px;
}
.password-container .form_input_field span.error {
    bottom: 0px;
}
.timezone_container > div{
    display: flex;
    justify-content: space-between;
}
.timezone_top{
    padding: 16px 28px 20px;
    background-color: #FCFCFE;
    align-items: center;
}
.timezone_title{
    font: 500 24px/31px "Roboto";
    color: #424242;
    margin-bottom: 0;
}
.timezone_content{
    font: normal 16px/21px "Roboto";
    margin-bottom: 0;
    color: #646670;
}
.timezone_middle{
    padding: 15px 28px 20px;
    align-items: center;
}
.timezone_date{
    font: 500 16px/21px "Roboto";
    color: #333758;
    margin-bottom: 0;
}
label.timezone_date{
    white-space: nowrap;
}
.timezone_bottom{
    align-items: flex-start;
    padding:17px 28px 25px;
    background-color: #FBFBFB;
}
.timezone_bottom > div{
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.timezone_bottom .ant-select{
    max-width: 300px;
    font: 500 16px/21px "Roboto" !important;
    color: #333758;
    margin-bottom: 14px;
    margin-top:0;
} 
.timezone_bottom .ant-select .ant-select-selection-item{
    padding-right:28px
}
.timezone_container .timezone_bottom .ant-select .ant-select-selector,
.timezone_container .timezone_bottom .ant-select .ant-select-selector input{
    background-color: transparent;
    border:none !important;
    box-shadow: none !important;
    height:21px !important;
    padding:0;
    width:300px;
}
.set-time-button{
    border: 1px solid #295195;
    background-color: #F1F6FF;
    border-radius: 5px;
    font: 500 11px/14px "Roboto";
    color: #295195;
    padding: 7px 8px 8px;
    margin-bottom: 0;
    cursor: pointer;
    transition: all 0.3s linear;
}
.set-time-button:hover{
    background-color: #295195;
    color:#ffffff;
}
.set-time-button:focus{
    outline: none;
}
/* Password & Security */
@media (min-width:769px) and (max-width: 1122px){
    .settings_page{
        padding: 60px 30px 51px; 
    }
    .timezone_top, .timezone_middle, .timezone_bottom{
        padding-right:10px;
        padding-left:10px;
    }
}
@media (max-width: 959px){
    .password_rules{
        margin-left: 0;
    }
}
@media (max-width: 768px){
    .billing_container {
        flex-wrap: wrap;
    }
    .billing_sidebar {
        width: 100%;
        min-height: auto;
    }
    .billing_page {
        width: 100%;
        padding: 20px 15px;
    }
    .settings_page{
        padding:20px 15px !important;
    }
    .settings_sidebar .sidebar_heading{
        text-align: left;
    }
    .timezone_container > div{
        flex-wrap: wrap;
    }
    .timezone_top, .timezone_middle, .timezone_bottom{
        padding-right:15px;
        padding-left:15px;
    }
    .timezone_top label, .timezone_middle label, .timezone_bottom label{
        width:100%;
        margin-bottom:5px;
    }
    .timezone_bottom > div{
        text-align: left;
        align-items: flex-start;
    }
}
@media (max-width: 480px){
    .notifications-container .add_company_action{
        padding: 25px 17px 31px;
    }
    .password_header {
        padding: 16px 15px 20px;
    }
    .password_body {
        padding: 18px 15px 33px;
    }
}