body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* error msg */
.error {
  color: red !important;
  font-size: 12px;
  display: block;
  min-height: 14px;
  line-height: 14px;
  font-family: "Roboto";
}

body * {
  font-family: "Roboto" !important;
}

/* Buttons-Hover */
.green-btn:hover, .green-btn:focus{
  background-color: #12A45E !important;
} 
.primary-btn:hover, .primary-btn:focus{
  background-color: #21BF732E !important;
}
.white-btn:hover, .white-btn:focus{
  background-color: #F5F5F5 !important;
}
.grey-btn:hover, .grey-btn:focus{
  background-color: #30396057 !important;
}
.red-btn:hover, .red-btn:focus{
  background-color: #9F1B3A !important;
}
.yellow-btn:hover, .yellow-btn:focus{
  background-color:#be8e08 !important;
}

/* custom scrollbar */
.custom_scrollbar > div:nth-child(3){
  right:5px !important;
  width:7px !important;
}
.custom_scrollbar > div:nth-child(3) > div{
  background-color: rgba(0,0,0,0.3) !important;
}
.custom_scrollbar > div:nth-child(3) > div:hover{
  background-color: rgba(0,0,0,0.5) !important;
}
/* pagination */
.mat-pagination{
  display: flex;
  justify-content: flex-end;
}
.mat-pagination ul li > button{
  font: 500 12px/14px "Roboto";
  color: #424242;
}
.mat-pagination .MuiSvgIcon-root{
  color: #424242 !important;
}
.mat-pagination ul li > button.MuiPaginationItem-page:hover {
  background-color: rgba(47, 61, 123, 0.2);
}
.mat-pagination ul li > button.MuiPaginationItem-page.Mui-selected{
  background-color: #2f3d7b;
  color:#ffffff;
}

/* error */
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: rgba(0, 0, 0, 0.23) !important;
}

/* Table Cell Cursor */
.tablecell_container td{
  cursor: pointer;
}